import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
const Home = lazy(() => import("../src/pages/Home"));
const renderLoader = () => <div className="loader"></div>;

const App = () => (
  <Router>
    <Suspense fallback={renderLoader()}>
      <Switch>
        {/* Paginas estaticas de web site */}
        <Route exact path="/" component={Home} />

        {/* Rutas no encontradas */}
        <Route
          render={function () {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    </Suspense>
  </Router>
);

export default App;